import Api from "./base";

class ChatApi extends Api {
	getChats = async () => {
		return await this.get(`/chats/chat`);
	};

	createChat = async (data) => {
		return await this.post(`/chats/chat/`, data);
	};

	updateChat = async (id, data) => {
		return await this.put(`/chats/chat/${id}`, data);
	};
}

const chatApi = new ChatApi();
export default chatApi;
